import { InvoiceStatus } from '../enums/invoice-status-enum';
import { SubscriptionStatus } from '../enums/subscription-status-enum';
import { INosSubscriptionDetailsResources } from '../nos-subscription-details.props.autogenerated';
import { ICoreContext, IDateFormatOptions } from '@msdyn365-commerce/core';
import { InvoicePeriod } from '../enums/invoice-period-enum';

export const getSubscriptionStatus = (resources: INosSubscriptionDetailsResources, status?: number) => {
    switch (status) {
        case SubscriptionStatus.ACTIVE:
            return resources.active;
        case SubscriptionStatus.ARCHIVED:
            return resources.archived;
        case SubscriptionStatus.LASTBILLING:
            return resources.lastBilling;
        case SubscriptionStatus.ONHOLD:
            return resources.onHold;
        case SubscriptionStatus.TERMINATED:
            return resources.terminated;
        default:
            return '';
    }
};

export const getInvoiceStatus = (resources: INosSubscriptionDetailsResources, status?: number) => {
    switch (status) {
        case InvoiceStatus.UNPAID:
            return resources.unpaid;
        case InvoiceStatus.PAID:
            return resources.paid;
        default:
            return '';
    }
};

export const getInvoiceFrequency = (resources: INosSubscriptionDetailsResources, period?: number) => {
    switch (period) {
        case InvoicePeriod.ONETIME:
            return resources.oneTime;
        case InvoicePeriod.DAILY:
            return resources.daily;
        case InvoicePeriod.MONTHLY:
            return resources.monthly;
        case InvoicePeriod.QUARTALY:
            return resources.quartaly;
        case InvoicePeriod.SEMIANNUALLY:
            return resources.semianually;
        case InvoicePeriod.ANNULALY:
            return resources.annually;
        default:
            return '';
    }
};

/**
 * Formats date.
 * @param context - Core context.
 * @param date - Date to display.
 * @returns String value.
 */
export const formatDate = (context: ICoreContext, date: Date | undefined) => {
    if (date === undefined) {
        return '';
    }

    const dateOptions: IDateFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return context.cultureFormatter.formatDate(date, dateOptions);
};

/**
 * Get the formatted currency.
 * @param props - The props.
 * @param price - The price.
 * @param shouldUseFreePriceText - The shouldUseFreePriceText.
 * @returns The formatted currency.
 */
export const formatCurrency = (props: any, currency: string | undefined, price: number | undefined): string => {
    if (price === undefined) {
        return '';
    }

    return props?.context?.cultureFormatter?.formatCurrency(price, currency);
};
